@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;600;700&display=swap");

* {
  transition-duration: 0.1s;
  font-family: Poppins, Roboto, Mulish, sans-serif;
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 7px;
  height: 15px;
}

::-webkit-scrollbar-thumb {
  background-color: #E2EFFE;
  border-radius: 100vh;
}

.scrollbar::-webkit-scrollbar-track {
  border-radius: 100vh;
  background: #eaf4ff;
}

.scrollbar::-webkit-scrollbar-thumb {
  background: #bce0fe;
  border-radius: 100vh;
  /* border: 3px solid #eaf4ff; */
}

.scrollbar::-webkit-scrollbar-thumb:hover {
  background: #bce0fe;
}
